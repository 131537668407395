import { request, generateUrl } from '@dbiqe/common';
import { getState, setPremiumAchNewVendorProddingFlag, setPremiumAchPreferences } from 'common/util/reduxUtil';
import systemConfig from 'system/configuration';
import { dark } from 'common/util/featureUtil';
import { getVendors } from 'components/VendorAssignmentPremiumACH/services';

export const TYPECODES = {
    CASH_CONCENTRATION_DISBURSEMENT: 'BDACHCCD',
    CASH_DISBURSEMENT: 'BDACHCD',
    CORPORATE_VENDOR_PAYMENT: 'BDACHCVP',
    NACHA_FILE_IMPORT_CASH_CONCENTRATION_DISBURSEMENT: 'BDNFICCD',
    PREMIUM_ACH: 'BDACHPRM',
    PREMIUM_ACH_CHILD: 'DACHPRM',
};

export const setPreferences = (name, value) => {
    setPremiumAchPreferences({
        [name]: value,
    });
};
export const getPreferences = (name) => {
    const { premiumAchPreferences } = getState();
    return premiumAchPreferences[name];
};
export const premiumAchEntitlementRequest = functionCode => (request.post(
    generateUrl('/accessService/hasAccess'),
    {
        actionMode: 'SELECT',
        functionCode,
        productCode: 'USACH',
        typeCode: TYPECODES.PREMIUM_ACH,
    },
));
export const loadPremiumAchEntitlements = () => {
    const vendorsAbortController = new AbortController();
    const isAdmin = systemConfig.isAdmin();
    const entitlementsPromise = Promise.all([
        premiumAchEntitlementRequest('BATCH'),
        premiumAchEntitlementRequest('BHTMPL'),
        isAdmin ? Promise.resolve() : getVendors({ signal: vendorsAbortController.signal }),
    ]);
    entitlementsPromise.then(([paymentResponse, templateResponse, vendors = []]) => {
        const isEntitled = paymentResponse?.data || templateResponse?.data;
        setPreferences('isPremiumAchEntitled', isEntitled);
        if (!isEntitled || !vendors.length) {
            setPreferences('hasCompletedEnrollment', false);
            return;
        }
        /*
         * There is currently not an API to understand if a company has completed enrollment.
         * We can go off of these rules which confirm that they have:
         * 1. The company is entitled for Premium ACH
         * 2. There is at least 1 active vendor
         * Placed this in the initial request for entitlements to keep traffic down to a minimum.
         */
        const hasActiveVendors = vendors.some(({ status }) => status === 'ACTIVE');
        setPreferences('hasCompletedEnrollment', hasActiveVendors);
    }).catch((e) => {
        console.log('Error Fetching Premium ACH entitlements', e);
    });
};
export const entitlementsForACHPaymentsRequest = typeCode => (request.post(
    generateUrl('/accessService/hasAccess'),
    {
        actionMode: 'SELECT',
        functionCode: 'BATCH',
        productCode: 'USACH',
        typeCode,
    },
));
export const loadUserEntitlementsForACHPaymentTypes = () => {
    const entitlementsPromise = Promise.all([
        entitlementsForACHPaymentsRequest(TYPECODES.CASH_CONCENTRATION_DISBURSEMENT),
        entitlementsForACHPaymentsRequest(TYPECODES.CASH_DISBURSEMENT),
        entitlementsForACHPaymentsRequest(TYPECODES.CORPORATE_VENDOR_PAYMENT),
        // eslint-disable-next-line max-len
        entitlementsForACHPaymentsRequest(TYPECODES.NACHA_FILE_IMPORT_CASH_CONCENTRATION_DISBURSEMENT),
    ]);
    entitlementsPromise.then((resp) => {
        setPreferences('isUserEntitledForACHPaymentTypes', resp[0]?.data || resp[1]?.data || resp[2]?.data || resp[3]?.data);
    }).catch((e) => {
        console.log('Error Fetching user entitlements for ACH payments', e);
    });
};
export const isCompanyEligibleForPremiumACH = () => {
    const premiumACHEligibilityPromise = request.get(generateUrl('/premiumACH/customerVendorOpportunity'));
    premiumACHEligibilityPromise.then((resp) => {
        if (resp.data) {
            const { vendors, status } = resp.data;
            const isEligible = !!vendors?.length && (!status || ['NEW_REQUEST', 'PENDING'].includes(status));
            setPreferences('isEligibleForPremiumACH', isEligible);
        }
    }).catch((e) => {
        console.log('Error Fetching customer vendor opportunity', e);
    });
};
export const hasNewPremiumAchVendors = () => {
    const payload = { userAction: 'LOGON' };
    return request.post(generateUrl('premiumACH/vendor/getLatest'), payload)
        .then(({ data: { proddingNeeded } }) => setPremiumAchNewVendorProddingFlag(proddingNeeded))
        .catch(console.log);
};
export const getMicrositePathFragments = () => {
    const startingPath = `/${window.Bottomline.appRoot}/premium-ach/enrollment/`;
    const docpath = document.location.pathname;
    const startIndex = docpath.indexOf(startingPath);
    const segments = docpath.substring(startIndex + startingPath.length).split('/');
    const guid = segments[0];
    const fragment = segments[1] ? `/${segments[1]}` : '/';
    return {
        guid,
        fragment,
    };
};

export const isPremiumAchEnrollmentPage = () => document.location.pathname.includes('premium-ach/enrollment');

const postAuthSetup = () => {
    if (dark.isLive('PREMIUMACH')) {
        return [
            loadPremiumAchEntitlements(),
            loadUserEntitlementsForACHPaymentTypes(),
            isCompanyEligibleForPremiumACH(),
            hasNewPremiumAchVendors(),
        ];
    }
    return [];
};

export default {
    setPremiumAchPreferences: setPreferences,
    getPremiumAchPreferences: getPreferences,
    getMicrositePathFragments,
    isPremiumAchEnrollmentPage,
    hasNewPremiumAchVendors,
    isCompanyEligibleForPremiumACH,
    loadPremiumAchEntitlements,
    loadUserEntitlementsForACHPaymentTypes,
    postAuthSetup,
};
