import palette from '../palette';

export default {
    fontFamily: 'Calibre, Roboto, Helvetica, Arial, sans-serif',

    primary: {
        backgroundColor: palette.cobalt,
        borderColor: palette.cobalt,
        focusColor: palette.resolutionBlue,
        hoverColor: palette.resolutionBlue,
        textColor: palette.white,
        disabledBackground: palette.dullLavender,
        disabledBorder: palette.dullLavender,
        disabledColor: palette.white,
        '&:hover': {
            borderColor: palette.resolutionBlue,
            color: palette.white
        },
    },

    secondary: {
        backgroundColor: palette.white,
        borderColor: palette.silver,
        focusColor: palette.gallery,
        hoverColor: palette.gallery,
        textColor: palette.cobalt,
        disabledBackground: palette.white,
        disabledColor: palette.dullLavender,
        '&:hover': {
            color: palette.cobalt,
        },
    },

    tertiary: {
        textColor: palette.cobalt,
        '&:hover': {
            color: palette.cobalt,
        },
        '&.disabled': {
            color: palette.dullLavender
        }
    },

    extraSmall: {
        borderRadius: 4
    },

    normal: {
        borderRadius: 4
    }
};