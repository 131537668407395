import buttons from './buttons';
import dbiqePageFooter from './dbiqePageFooter';
import dbiqePageHeader from './dbiqePageHeader';
import dbiqeStopPayments from './dbiqeStopPayments';
import formComponents from './form-components';
import icons from './icons';
import modal from './modal';

export default {
    buttons,
    dbiqePageFooter,
    dbiqePageHeader,
    dbiqeStopPayments,
    formComponents,
    icons,
    modal
};