import $ from 'jquery';
import util from '@dbiqe/glu-core/src/util';
import locale from '@glu/locale';
import dateUtil from 'common/util/dateUtil';
import services from 'services';
import Formatter from 'system/utilities/format';
import http from '@dbiqe/glu-core/src/http';
import constants from 'common/dynamicPages/api/constants';
import userInfo from 'etc/userInfo';
import number from 'numeral';
import Decimals from 'common/dynamicPages/api/decimals';
import validatorPatterns from 'system/validatorPatterns';
import informationalMessage from 'common/util/informationalMessage';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import systemConfig from 'system/configuration';
import { appBus } from '@dbiqe/glu-core';

const PaymentUtil = ({

    /**
     * Evaluates an amount and determines if it's valid or not
     * @param  {String} amount  the amount to be evaluated
     * @return {boolean} to indicate whether or not the amount passed is valid
     */
    verifyValidAmount(amount) {
        if (amount === null) {
            return false;
        }
        return Formatter.unformatNumber(amount) > 0;
    },

    /**
     * Toggle the holdFlag lock icon based on selection of checked or not
     * @param {field} field
     */
    toggleCheckboxLock(field) {
        if (field.length <= 1) {
            return;
        }

        const $f = $(field[1]);

        if (field[0].checked && ($f.hasClass('icon-unlock') || $f.hasClass('icon-lock'))) {
            $f.toggleClass('icon-unlock', true);
            $f.toggleClass('icon-lock', false);
            $f.toggleClass('disabled', true);
        } else {
            $f.toggleClass('disabled', false);
        }
    },

    /**
     * Correctly updates the lock icon state upon when the user clicks it
     * @param  {Event} e  Jquery click handler event
     * @param  {Model} model
     * @param  {Model} parentModel
     */
    toggleLock(e, model, parentModel) {
        const isLocked = e.currentTarget.classList.contains('icon-lock');
        const fieldName = e.currentTarget.name;
        if (['AMOUNT', 'AMOUNT1', 'AMOUNT2', 'AMOUNT3', 'CREDIT_AMOUNT', 'DEBIT_AMOUNT'].includes(fieldName)) {
            if (!isLocked && model) {
                model.set('TEMPLATE_MAX_AMOUNT', '');
            }
            if (!isLocked && parentModel) {
                parentModel.set('TEMPLATE_MAX_AMOUNT', '');
            }
            $('[name="TEMPLATE_MAX_AMOUNT"]').prop('readonly', !isLocked);
        } else if (fieldName === 'HOLDFLAG' && !isLocked && ((model && model.get('HOLDFLAG') === '1') || $('[name="HOLDFLAG"]')[0].checked)) {
            return;
        }
        /**
         * if we are currently locked, then the intention is to unlock..
         * We must update the models
         */
        if (model) {
            this.updateLockedAttribute(model, fieldName, isLocked);
            // for communication with AccountBalanceComboboxWrapper
            appBus.trigger('lockedFields:update', model.get('LOCKED_FIELDS'));
        }
        if (parentModel) {
            this.updateLockedAttribute(parentModel, fieldName, isLocked);
            // for communication with AccountBalanceComboboxWrapper
            appBus.trigger('lockedFields:update', parentModel.get('LOCKED_FIELDS'));
        }

        if (isLocked) {
            e.currentTarget.classList.add('icon-unlock');
            e.currentTarget.classList.remove('icon-lock');
        } else {
            e.currentTarget.classList.remove('icon-unlock');
            e.currentTarget.classList.add('icon-lock');
        }

        /**
         * HACK: temporary hack until a UI dev can help out
         * we also need to make sure there are no other elements with this same name and
         * same classList
         */
        const elementList = $('.icon-lock, .icon-unlock');
        util.each(elementList, (element) => {
            if (element && element.name === fieldName) {
                const $e = $(element);
                $e.toggleClass('icon-unlock', isLocked);
                $e.toggleClass('icon-lock', !isLocked);
            }
        });
    },

    /**
     * helper function to remove value from delimited model attribute
     * @param {Model} model
     * @param {String} fieldName
     * @param {boolean} isLocked
     */
    updateLockedAttribute(model, fieldName, isLocked) {
        const modelParam = model;
        let lockedFields = [];
        let unlockedFields = [];
        if (modelParam.has('LOCKED_FIELDS')) {
            lockedFields = modelParam.get('LOCKED_FIELDS').split(',');
        } else if (modelParam.lockedFields) {
            ({ lockedFields } = modelParam);
        }
        const inList = lockedFields.includes(fieldName);

        if (inList && isLocked) {
            // if in list of locked fields and locked, remove from locked fields list
            lockedFields = lockedFields.filter(name => name !== fieldName);
            unlockedFields.push(fieldName);
        } else if (!inList && !isLocked) {
            /**
             * if not in the list of locked fields, and is not currently locked..
             * then add to locked fields
             */
            lockedFields.push(fieldName);
            unlockedFields = unlockedFields.filter(name => name !== fieldName);
        } else if (!inList && isLocked) {
            // it was locked by default, now unlocked
            unlockedFields.push(fieldName);
        } else {
            // Don't update model if it hasn't changed.
            return;
        }
        modelParam.set('LOCKED_FIELDS', lockedFields.join(','));
        modelParam.lockedFields = lockedFields;
        modelParam.set('UNLOCKED_FIELDS', unlockedFields.join(','));
        modelParam.unlockedFields = unlockedFields;
    },

    /*
     * In some instances, we do not have any values for unlocked fields on the model.
     * In these scenarios, we need to employ some extra logic to determine if the field
     * should be locked or not.
     */
    checkLockStatusByContext({
        model, isInLockedFields, isInUnlockedFields,
    }) {
        const {
            context: {
                actionMode,
                copySource: isCopy,
            },
        } = model;
        const functionCode = model.jsonData?.typeInfo?.functionCode;
        const isTemplate = functionCode === 'TMPL' || functionCode === 'BHTMPL';
        const mode = actionMode?.toUpperCase();
        const isModify = mode === 'MODIFY';
        const isInsert = mode === 'INSERT';
        const isRestore = mode === 'RESTORE';
        const isReverse = mode === 'REVERSE';
        const isRepair = mode === 'REPAIR';
        const isView = mode === 'VIEW';

        const shouldDoExtraUnlockCheck = [
            isInsert && isCopy,
            isTemplate && (isModify || isView),
            (isRestore || isReverse || isRepair || isCopy),
        ].some(i => i);
        let isUnlocked = isInUnlockedFields;
        if (shouldDoExtraUnlockCheck && !isInUnlockedFields) {
            isUnlocked = !isInLockedFields;
        }
        return isUnlocked;
    },

    /**
     * Determine if the field should be locked
     * @param {Object} params
     * @param {Model} params.model
     * @param {string} params.fieldName
     * @param {boolean} params.lockedByDefault
     * @returns {boolean}
     */
    isFieldLocked({ model, fieldName, lockedByDefault }) {
        const { lockedFields = [], unlockedFields = [] } = model;
        const isInUnlockedFields = unlockedFields.includes(fieldName);
        const isInLockedFields = lockedFields.includes(fieldName);
        const isUnlocked = this.checkLockStatusByContext({
            model,
            isInLockedFields,
            isInUnlockedFields,
        });

        /*
         * HACK NH-196777 - Backbone mdf triggers rerenders losing the component state,
         * so pull the locked status from the model.
         * When both unlocked and locked fields do not contain the field,
         * this indicates that a user hasn't interacted with it yet. Consecutive
         * renders trigger by MDF will copy over the previous model values though,
         * so the locked value will persist.
         */
        if (!isUnlocked && !isInLockedFields) {
            return lockedByDefault;
        }
        return lockedFields.includes(fieldName);
    },

    /**
     * Correctly sets the lock icons disabled state on the amount input change
     * @param  {Event} e  Jquery change handler event
     * @param  {Model} model
     */
    changeAmount(e, model) {
        this.runChangeAmount(e.currentTarget, model);
    },

    /**
     * helper function to set the amount input lock icons state correctly when changed
     * @param {Object} currentTarget
     * @param {Model} model
     */
    runChangeAmount(currentTarget, model) {
        let iconContainer = $(currentTarget).closest('.field-container').children('.mdf-input-icon-container').get(0);
        const fieldName = currentTarget.name;

        if (iconContainer === undefined) {
            iconContainer = $(currentTarget).closest('.widget-field-container').children('.mdf-input-icon-container').get(0);
        }

        // get a reference to the lockIcon itself
        const lockIcon = $(iconContainer).children('.icon-lock').get(0) || $(iconContainer).children('.icon-unlock').get(0);

        // exit function early if there's no lock icon
        if (iconContainer === undefined || lockIcon === undefined) {
            return;
        }

        const lockIsEnabled = !lockIcon.classList.contains('disabled');
        let lockedFields = [];
        if (model && model.has('LOCKED_FIELDS')) {
            lockedFields = model.get('LOCKED_FIELDS');
            if (!lockedFields
                && model.fieldData.LOCKED_FIELDS
                && model.fieldData.LOCKED_FIELDS.value !== null) {
                lockedFields = model.fieldData.LOCKED_FIELDS.value;
            }
            lockedFields = lockedFields.split(',');
        }
        let removeAmountField = false;

        /**
         * if the user has entered an appropriate amount and the lock is disabled -
         * enable the lock icon
         */
        const amount = currentTarget.value;
        if (this.verifyValidAmount(amount) && !lockIsEnabled) {
            lockIcon.classList.remove('disabled');
            /**
             * if the user has not entered an appropriate amount and the lock icon is enabled -
             * disable the lock icon and unlock it
             */
        } else if (!this.verifyValidAmount(amount) && lockIsEnabled) {
            lockIcon.classList.add('disabled');
            lockIcon.classList.add('icon-unlock');
            lockIcon.classList.remove('icon-lock');
            $('[name="TEMPLATE_MAX_AMOUNT"]').prop('readonly', false);
            removeAmountField = true;
        }

        /**
         * check if we are passed in a model and the field is locked. If so, this is on a
         * render and we must clear and protect template max amount
         */
        if (model && (['AMOUNT', 'AMOUNT1', 'AMOUNT2', 'AMOUNT3', 'CREDIT_AMOUNT', 'DEBIT_AMOUNT'].includes(fieldName)) && model.has('LOCKED_FIELDS')) {
            if (removeAmountField) {
                lockedFields.filter(name => name !== fieldName);
                model.set('LOCKED_FIELDS', lockedFields.join(','));
                this.updateLockedAttribute(model, fieldName, true);
            } else if (lockedFields.indexOf(fieldName) > -1) {
                model.set('TEMPLATE_MAX_AMOUNT', '');
                $('[name="TEMPLATE_MAX_AMOUNT"]').prop('readonly', true);
            }
        }
    },

    /**
     * Retrieve all locked inputs and store their names
     */
    compileLockedFields(view) {
        let locks = Array.from(view.el.getElementsByClassName('icon-lock'));
        let childLocks;
        // need to gather the locked fields from bene popup modal as well, if it exists
        if (view.modalChildView && view.modalChildView.batchChildView) {
            childLocks = Array.from(view.modalChildView.batchChildView.el.getElementsByClassName('icon-lock'));
            locks = locks.concat(childLocks);
        }
        const listOfLockedFields = locks.map(lock => lock.name).filter((val, idx, source) => idx === source.findIndex(val2 => val2 === val)).join(',');
        view.model.set('LOCKED_FIELDS', listOfLockedFields);
    },

    updateLockedFields(view) {
        let lockedFields = view.model.get('LOCKED_FIELDS');
        if (!util.isNullOrUndefined(lockedFields)) {
            let unlockedFields = view.model.get('UNLOCKED_FIELDS');
            if (!util.isNullOrUndefined(unlockedFields)) {
                unlockedFields = unlockedFields.split(',');
                lockedFields = lockedFields.split(',').filter(item => !unlockedFields.includes(item)).join(',');
                view.model.set('LOCKED_FIELDS', lockedFields);
            }
        }
    },

    validateTemplateInfo(options, model) {
        const optionsParam = options;
        const $buttonSave = $('button[name="save"]');
        optionsParam.templateCode.shouldBeRequiredWhen(optionsParam.saveTemplateChecked);
        optionsParam.templateDescription.shouldBeRequiredWhen(optionsParam.saveTemplateChecked);
        if (optionsParam.saveTemplateChecked) {
            optionsParam.templateCode
                .shouldMatchPattern(validatorPatterns.TEMPLATECODE_PATTERN);
            $(`[name=${optionsParam.tempCodeFieldName}]`).parents('.field-container').addClass('required');
            $(`[name=${optionsParam.tempDesFieldName}]`).parents('.field-container').addClass('required');
            optionsParam.templateCode.$el.get(0).pattern = validatorPatterns.TEMPLATECODE_PATTERN;
        } else {
            $(`[name=${optionsParam.tempCodeFieldName}]`).parents('.field-container').removeClass('required');
            $(`[name=${optionsParam.tempDesFieldName}]`).parents('.field-container').removeClass('required');
        }
        $buttonSave.off('click.template');
        $buttonSave.on('click.template', () => {
            let divTempCode;
            let divTempDesc;
            if (model.get('SAVEASTEMPLATE') === '1' && model.get(optionsParam.tempCodeFieldName) === '') {
                divTempCode = $(`[name=${optionsParam.tempCodeFieldName}]`).parents('.field-container');
                divTempCode.addClass('has-error');
                $('.help-block', divTempCode).text(locale.get('PAYJS.TmplCodeMandatory'));
            } else if (model.get('SAVEASTEMPLATE') === '1' && (model.get(optionsParam.tempCodeFieldName).indexOf(' ') > -1 || model.get(optionsParam.tempCodeFieldName).indexOf('\t') > -1)) {
                divTempCode = $(`[name=${optionsParam.tempCodeFieldName}]`).parents('.field-container');
                divTempCode.addClass('has-error');
                $('.help-block', divTempCode).text(locale.get('RTGS.TemplateCodeCannotHaveSpaces'));
            } else if (model.get('SAVEASTEMPLATE') === '1' && optionsParam.templateCode.$el.get(0).validity.patternMismatch) {
                divTempCode = $(`[name=${optionsParam.tempCodeFieldName}]`).parents('.field-container');
                divTempCode.addClass('has-error');
                $('.help-block', divTempCode).text(locale.get('PAYJS.TmplCodeMisMatch'));
            } else {
                divTempCode = $(`[name=${optionsParam.tempCodeFieldName}]`).parents('.field-container');
                divTempCode.removeClass('has-error');
                $('.help-block', divTempCode).text('');
            }
            if (model.get('SAVEASTEMPLATE') === '1' && model.get(optionsParam.tempDesFieldName) === '') {
                divTempDesc = $(`[name=${optionsParam.tempDesFieldName}]`).parents('.field-container');
                divTempDesc.addClass('has-error');
                $('.help-block', divTempDesc).text(locale.get('PAYJS.TmplDescriptionMandatory'));
            } else {
                divTempDesc = $(`[name=${optionsParam.tempDesFieldName}]`).parents('.field-container');
                divTempDesc.removeClass('has-error');
                $('.help-block', divTempDesc).text('');
            }
        });
    },

    getPaymentMessage(payment, page) {
        let pmtMessageKey = 'PAY.PMT.MESSAGE.';
        let pmtMessage = '';

        pmtMessageKey += payment.typeInfo.productCode;
        pmtMessageKey = `${pmtMessageKey}.`;
        pmtMessageKey += payment.typeInfo.functionCode;
        pmtMessageKey = `${pmtMessageKey}.`;
        pmtMessageKey += payment.typeInfo.typeCode;

        if (payment.subtype) {
            pmtMessageKey = `${pmtMessageKey}.${payment.subtype}`;
        }

        pmtMessage = locale.get(pmtMessageKey);

        pmtMessage = (pmtMessage.indexOf('??') === 0) ? '' : pmtMessage;

        if (pmtMessage !== '') {
            page.pmtMessageRegion.show(informationalMessage.showMessage(pmtMessage));
        }
    },

    showCutoff(cutoffInfo, uiDatePicker, type, mode, status) {
        // if we dont have a datepicker to attach to.. dont show
        if (!uiDatePicker || uiDatePicker.length <= 0) {
            return;
        }

        /*
         * if there already exists a 'valueDateWidget' cutoff section then it has already been
         * updated with the model
         */
        if (uiDatePicker.siblings('[data-section="cutoff-date-section"]').length) {
            return;
        }

        /*
         * if we are in view mode, or reverse mode, or status is entered, incomplete approval,
         * needs rate, or 2nd approval needed, reversal awaiting approval, partial reversal
         * awaiting approval, reversal (incomplete approval), partial reversal
         * (incomplete approval) or hideCutoff is defined and includes the type,
         * do NOT show cutoff verbiage
         */
        if (mode && (['VIEW', 'SELECT'].indexOf(mode.toUpperCase()) >= 0) && (['EN', 'IA', 'RT', 'HV', 'RA', 'RI', 'IV', 'PI'].indexOf(status) < 0)) {
            return;
        }

        // if cutoff time is available and config allows, then show it
        let $cutoff = uiDatePicker.siblings('[data-hook="cutoff"]');

        const hideCutoff = serverConfigParams.get('hideCutoff');
        const showCutoff = !hideCutoff || hideCutoff.indexOf(type) < 0;
        let message = 'PAY.PaymentMustBeApprovedBy';

        if (type === 'TRANSFER') {
            message = 'PAY.TransferMustBeApprovedBy';
        }
        if (cutoffInfo && showCutoff) {
            // add container if missing
            if (!$cutoff.length) {
                $cutoff = $(`<div class="field-container-lg field-container" data-hook="cutoff"><span class="textline-field">${locale.get(message)} ${cutoffInfo}</span></div>`);
                const $helpblock = uiDatePicker.parent().find('span.help-block');
                if ($helpblock.length) {
                    $helpblock.after($cutoff);
                } else {
                    uiDatePicker.after($cutoff);
                }
            }
            // replace cutoff value content
            $cutoff.find('span').text(`${locale.get(message)} ${cutoffInfo}`);
        } else {
            $cutoff.remove();
        }
    },

    /*
     * @method showTemplateMaxAmtForPayment
     * @param {Model object} model - the payment model
     * @param {form element} paymentAmountblock: the payment amount block under which Template
     * Amount max should show
     * @param {boolean} shouldShow - indicate whether to show Template Amount max
     * @param {boolean} addBefore - indicate whether template max should be added before or after
     * paymentAmountblock
     * @param {boolean} isAch -- indicate whether it's ach payment to show Template Amount max
     * for payments created from a template that has the value set
     * @param {boolean} i
     */
    showTemplateMaxAmtForPayment(model, paymentAmountblock, shouldShow, addBefore, isAch) {
        if (!paymentAmountblock || paymentAmountblock.length <= 0) {
            return;
        }

        let helperblock = paymentAmountblock.next('[class*="textline-field"]');

        // If the templateMaxAmt was added BEFORE the amount block, retrieve it
        if (!helperblock.get(0)) {
            helperblock = paymentAmountblock.prev('[class*="textline-field"]');
        }

        const amtFormatted = number(model.get('TEMPLATE_MAX_AMOUNT') || 0).format(userInfo.getNumberFormat());
        const message = isAch ? `${amtFormatted} ${model.get('TEMPLATE_MAX_CURRENCY')}` : `${locale.get('PAY.paymentmaximum')} ${amtFormatted} ${model.get('TEMPLATE_MAX_CURRENCY')}`;
        if (shouldShow) {
            // add container if missing
            if (helperblock.length < 1) {
                helperblock = $(`<span class="textline-field">${message}</span>`);
                if (isAch) {
                    helperblock = $(`<p class="template-max-amount textline-field">${locale.get('PAY.paymentmaximum')}</p><p class="template-max-amount textline-field">${amtFormatted} ${model.get('TEMPLATE_MAX_CURRENCY')}</p>`);
                }
                if (addBefore) {
                    paymentAmountblock.before(helperblock);
                } else {
                    paymentAmountblock.after(helperblock);
                }
            } else {
                if (isAch) {
                    helperblock = helperblock.next('[class*="textline-field"]');
                }
                helperblock.text(message);
            }
        } else {
            helperblock.empty();
        }
    },

    /*
     * @method shouldShowTemplateMaxAmt
     * @param {boolean} allowMaxTmplAmount - indicate whether or not Template Max feature is
     * enabled for the payment type
     * @param {view} formView
     * @param {Model object} model - the payment model
     * @param {form element} amtBlock: the payment amount block under which
     * Template Amount max should show
     * @param {string} functionCode - payment function code
     * For template
     */
    shouldShowTemplateMaxAmt(allowMaxTmplAmount, formView, model, amtBlock, functionCode) {
        if (allowMaxTmplAmount === '1' && (formView.state === 'insert' || formView.state === 'modify' || formView.state === 'restore')) {
            if (model.get('TEMPLATE_MAX_CURRENCY') === '' && model.fieldData.TEMPLATE_MAX_CURRENCY !== null) {
                model.set('TEMPLATE_MAX_CURRENCY', model.fieldData.TEMPLATE_MAX_CURRENCY.value);
            }
            if (functionCode === 'TMPL') {
                formView.$(`label[for=TEMPLATE_MAX_AMOUNT-${formView.cid}]`).parent().show();
            } else {
                this.showTemplateMaxAmtForPayment(model, amtBlock, model.get('TEMPLATE_MAX_AMOUNT') !== '');
            }
        } else {
            formView.$(`label[for=TEMPLATE_MAX_AMOUNT-${formView.cid}]`).parent().hide();
            formView.$('.restricted-checkbox')?.removeClass('restricted-checkbox');
            if ((!systemConfig.isAdmin() && allowMaxTmplAmount !== '1')
                || (formView.state === 'view' && model.get('TEMPLATE_MAX_AMOUNT') === '')) {
                formView.$('#TEMPLATE_MAX_AMOUNT').hide(); // at view mode the above won't find anything
            }
        }
    },

    /*
     * update the cutoff date section of the value date widget by consulting back end
     *
     * @param productCode {String} - A page-specific configuration that can affect needed logic
     * @param functionCode {String} - A page-specific configuration that can affect needed logic
     * @param typeCode {String} - A page-specific configuration that can affect needed logic
     * @param subType {String} - A page-specific configuration that can affect needed logic
     * @param formState {String} - The state of this form
     * @param model {Object} - The model of this form
     * @param datePicker {Jquery Object} - The date picker who's cutoff date needs updating
     */
    updateCutoff(productCode, functionCode, typeCode, subType, formState, model, datePicker) {
        const processActionService = services.generateUrl(constants.URL_PROCESS_SERVICE_ACTION);
        const hidecutoff = serverConfigParams.get('hideCutoff');
        const showCutoff = !hidecutoff || hidecutoff.indexOf(typeCode) < 0;
        const sameDayACH = () => {
            if (model.get('SAMEDAYACH') === locale.get('common.yes')) {
                return '1';
            }
            if (model.get('SAMEDAYACH') === locale.get('common.no')) {
                return '0';
            }
            return model.get('SAMEDAYACH');
        };

        const inputData = [{
            name: 'REQUESTTYPE',
            value: 'cutoffdateProcessor',
        }, {
            name: 'PRODUCT',
            value: productCode,
        }, {
            name: 'FUNCTION',
            value: functionCode,
        }, {
            name: 'TYPE',
            value: typeCode,
        }, {
            name: 'SUBTYPE',
            value: subType === undefined ? 'NACHA' : subType,
        }, {
            name: 'MODE',
            value: formState,
        }, {
            name: 'DEBIT_ACCOUNT_NUMBER',
            value: model.get('ORIGCOMPID') || model.get('DEBIT_ACCOUNT_NUMBER'),
        }, {
            name: 'SAMEDAYACH',
            value: sameDayACH(),
        }, {
            name: 'BANKCODE',
            value: model.get('BANKCODE') || model.get('DEBIT_BANK_CODE'),
        }, {
            name: 'DEBITCURRENCY',
            value: model.get('ORIGCURRENCYCODE') || model.get('DEBIT_CURRENCY'),
        }, {
            name: 'EFFECTIVEDATE',
            value: model.get('EFFECTIVEDATE') || model.get('TRAN_DATE'),
        }, {
            name: 'RECEIVBANKCOUNTRYCODE',
            value: model.get('DESTCOUNTRYCODE') || model.get('BENE_BANK_COUNTRY'),
        }, {
            name: 'RECEIVABA',
            value: model.get('RECEIVABA'),
        }, {
            name: 'ENTRYCLASS',
            value: model.get('ENTRYCLASS'),
        }, {
            name: 'ORIGCOUNTRY',
            value: model.get('ORIGCOUNTRY') || model.get('DEBIT_COUNTRY'),
        }, {
            name: 'CREDIT_CURRENCY',
            value: model.get('CREDIT_CURRENCY'),
        }, {
            name: 'BENE_BANK_ID',
            value: model.get('BENE_BANK_ID'),
        }, {
            name: 'BENE_BANK_IDTYPE',
            value: model.get('BENE_BANK_IDTYPE'),
        }, {
            name: 'GRID_NAME',
            value: model.jsonData ? model.jsonData.childGridName : '',
        }, {
            name: 'TEMPLATE_ID',
            value: model.get('id'),
        }, {
            name: 'ENTRYDESC',
            value: model.get('ENTRYDESC'),
        }, {
            name: 'ACCOUNTFILTER',
            value: model.get('ACCOUNTFILTER'),
        }, {
            name: 'BATCHSEQNUM',
            value: model.get('BATCHSEQNUM'),
        }];

        if (showCutoff) {
            // only make the call to the server, if we are going to show the cutoff.
            http.post(processActionService, {
                item: inputData,
            }, (result) => {
                // extract the cutoff, and onus
                util.each(result.item, (entry) => {
                    if (entry.name === 'CUTOFF_INFO') {
                        model.set('CUTOFF_INFO', entry.value);
                        /**
                         * if cutoff time is available and config allows, then show it.
                         * dont run for template creation
                         */
                        if (functionCode !== 'BHTMPL' && functionCode !== 'TMPL') {
                            PaymentUtil.showCutoff(model.get('CUTOFF_INFO'), datePicker, typeCode);
                        }
                    } else if (entry.name === 'ONUS') {
                        model.set('ONUS', entry.value);
                    } else if (entry.name === 'STALESAMEDAYACH') {
                        appBus.trigger('stalePayment:warningMessage', entry.value);
                    }
                });
            });
        }
    },

    /*
     * In specific cases, we may need to hide the lookup icon for specific field
     * and not all fields of that ui field type
     * @method hideLookupIfProtected
     * @param {string} viewFieldName - fieldname to be checked and hide
     * @param {string} modelFieldName = fieldname to get protected attr from model
     * @param {Model object} model - the payment model
     * @param {view} formView
     *
     */
    hideLookupIfProtected(viewFieldName, modelFieldName, model, formView) {
        const $fieldNameLookup = formView.$(`[name="${viewFieldName}"]`);
        // protect lookup icon if necessary, for bene_bank_id lookup
        const fInfo = util.findWhere(
            model.jsonData.fieldInfoList,
            {
                name: modelFieldName,
            },
        );
        if (fInfo && fInfo.protected) {
            $fieldNameLookup.addClass('hidden');
        }
    },

    /**
     * when recur checkbox is selected, we need to make sure the amount field (passed in) is
     * made mandatory
     *
     * @param {object} $field
     * @param {string} fieldName
     * @param {Model} model
     * @param {boolean} required
     * @param {boolean} isWidgetField - if the amount field visibility is controled by a
     *     containing widget such as the amount fields in the fx amount widget for
     *     international cross currency wires and transfers. Default is false.
     */
    toggleAmountRequired($field, fieldName, model, required, isWidgetField = false) {
        $field.prop('required', required);
        $field.closest(isWidgetField ? '.widget-field-container' : '.field-container')
            .toggleClass('required', required);
        if (required) {
            model.addValidator(
                fieldName,
                {
                    description: model.fieldData[fieldName].fieldLabel,
                    exists: true,
                    minValue: 0.01,
                },
            );
        } else {
            model.removeValidator(fieldName);
        }
    },

    /*
     * these two toggle back and forth, cant have both, only one or the other
     * @method toggleZdlPrenoteFlags
     * @param {object} zeroDollarLiveFlag - zero dollar live field
     * @param {object} prenoteFlag - prenote field
     * @param {boolean} [overrideZdl] - zero dollar live override value
     * @param {boolean} [overridePrenote] - prenote override value
     */
    toggleZdlPrenoteFlags(zeroDollarLiveFlag, prenoteFlag, overrideZdl, overridePrenote) {
        const zdl = overrideZdl || zeroDollarLiveFlag.$el.is(':checked');
        const prenote = overridePrenote || prenoteFlag.$el.is(':checked');
        if (zdl) {
            prenoteFlag.$el.attr('checked', false);
        }
        prenoteFlag.shouldBeReadOnly(zdl);
        if (prenote) {
            zeroDollarLiveFlag.$el.attr('checked', false);
        }
        zeroDollarLiveFlag.shouldBeReadOnly(prenote);
    },

    isTemplate(functionCode) {
        let res = false;
        if (functionCode != null) {
            switch (functionCode) {
            case 'TMPL':
            case 'BHTMPL':
            case 'REQTMPL':
                res = true;
                break;
            default:
                break;
            }
        }
        return res;
    },

    /**
     * Flags group of fields as required when necessary.
     * These fields follow an "all or none" rule: if any one of them is present,
     * all must be present, otherwise they are all optional.
     * @param {string} groupName : name of the fieldGroup
     * @param {array} fields : an array of field names
     * @param {boolean} required
     * @param {object} view : the form view where holds the fields
     * @param {string} parentSel : a selector used to find parent to toggle
     */
    toggleFieldsMandatory(groupName, fields, required, view, parentSel) {
        const mandatory = required || fields.some(field => !!view.model.get(field));

        if (view.shouldRequire[groupName] !== mandatory) {
            // toggle
            util.each(fields, (fieldName) => {
                let $field = view.$(`label[for="${fieldName}"]`);
                if ($field.length === 0) {
                    $field = view.$(`[name="${fieldName}"]`);
                }
                const $parent = parentSel ? $field.parents(parentSel) : $field.parent();

                if (mandatory) {
                    view.model.addValidator(fieldName, {
                        description: $field.text(),
                        exists: true,
                    });
                } else {
                    // remove validator, and clear errors if available
                    view.model.removeValidator(fieldName);
                    $parent.toggleClass('has-error', false);
                    $parent.find('.help-block').empty();
                }
                $parent.toggleClass('required', mandatory);
            });
            // eslint-disable-next-line no-param-reassign
            view.shouldRequire[groupName] = mandatory;
        }
    },

    /**
     * Determine if this is a Reimburse version of a Payroll payment
     * @param {Object} model - model containign the payment attributes
     * @returns {Boolean}
     */
    isReimburse(model) {
        return model.get('ENTRYDESC') === 'Expense Reimbursement'
            || model.get('CMB_TYPE_DESCRIPTION') === 'ACH.reimburse'
            || model.get('CMB_TYPE_DESCRIPTION') === locale.get('ACH.reimburse');
    },

    /**
     * @param {object} model
     * @return {Number} - The desired amount to update the summary with
     */
    determineAmountTypeToUse(model) {
        let amountTypeToUse;

        // check if the model has a flag indicating which amount type to use
        if (model.get('ENTERED_AMOUNT_FLAG')) {
            if (model.get('ENTERED_AMOUNT_FLAG') === 'C') {
                amountTypeToUse = 'CREDIT';
            } else if (model.get('ENTERED_AMOUNT_FLAG') === 'D') {
                amountTypeToUse = 'DEBIT';
            }
        } else {
            /*
             * The model does not have a flag to indicate which amount type to use, so
             * we check for a credit currency to see if a credit amount is relevant
             */
            amountTypeToUse = util.isEmpty(model.get('CREDIT_CURRENCY')) ? 'DEBIT' : 'CREDIT';
        }

        return amountTypeToUse;
    },

    /**
     *
     * @param {object} model
     * @param {object} view
     * @param {String} specifiedAmountType - an optional amount type that the user can
     * specify to indicate what model atttribute to use when updating the summary
     */
    updateRTGSSummaryTotal(model, view, specifiedAmountType) {
        let amountTypeToUse;

        // if there is a specified amount type to use, honor it
        if (specifiedAmountType) {
            amountTypeToUse = specifiedAmountType;
        } else {
            amountTypeToUse = this.determineAmountTypeToUse(model, specifiedAmountType);
        }

        const credAmount = (model.get('CREDIT_AMOUNT') ?? '').toString().trim();
        const debAmount = (model.get('DEBIT_AMOUNT') ?? '').toString().trim();
        const theAmount = (amountTypeToUse === 'CREDIT' ? credAmount : debAmount) || 0;
        const currency = model.get(`${amountTypeToUse}_CURRENCY`);
        const formattedAmt = number(theAmount).format(Decimals.getCurrencyFormat(currency));
        const date = model.get('VALUE_DATE') || new Date();
        const formattedDate = Formatter.formatDateFromUserFormat(
            date,
            dateUtil.PAYMENT_SUMMARY_DATE_FORMAT,
        );

        // update the summary total line at the bottom in the summary section
        const params = [{ value: formattedAmt, className: 'summary-large-text' }, { value: currency }, { value: '1' }, { value: locale.get('PAY.beneficiary') }, { value: formattedDate !== 'Invalid date' ? formattedDate : '' }];
        view.appBus.trigger(`update:localeMessage:${view.summaryLocaleKey}`, params);
        return params;
    },

    /**
     *
     * @param {*} model
     * @param {*} view
     * @param {*} overrides
     */
    updateACHSummaryTotal(model, view, overrides = {}) {
        const beneCount = model.get('TOTALNUM') || overrides.TOTALNUM || 0;
        const theAmount = model.get('TOTALAMT') || overrides.TOTALAMT || 0;
        const localeKey = view.summaryLocaleKey || overrides.localeKey;
        const formattedAmt = Formatter.formatCurrency(theAmount);
        const beneText = (beneCount <= 1) ? locale.get('PAY.beneficiary') : locale.get('PAY.beneficiaries');
        const dt = model.get('EFFECTIVEDATE') || new Date();
        const formattedDate = Formatter.formatDateFromUserFormat(
            dt,
            dateUtil.PAYMENT_SUMMARY_DATE_FORMAT,
        );

        const currency = model.get('ORIGCURRENCYCODE') || 'USD';
        const params = [{ value: formattedAmt, className: 'summary-large-text' }, { value: currency }, { value: beneCount }, { value: beneText }, { value: formattedDate !== 'Invalid date' ? formattedDate : '' }];
        view.appBus.trigger(`update:localeMessage:${localeKey}`, params);
    },

    /**
     * Indicates whether the data conditions in the payment model for setting and displaying
     * a value and transaction date are satisfied.
     * @param {type} model The payment model to be evaluated
     * @returns {Boolean} true indicates the conditions are satisfied, false that they are not.
     */
    isReadyValueDateRetrieval(model) {
        const typecode = model.get('TYPE');
        if (model.get('PRODUCT') === 'RTGS' && model.get('FUNCTION') === 'INST') {
            if (typecode === 'INTL') {
                return !!(model.get('ACCOUNTFILTER') && (model.get('BENE_BANK_ID') || model.get('BENEBANKIDENTRYMETHOD') === 'FREEFORM') && model.get('CREDIT_CURRENCY'));
            }
            if (typecode === 'FEDWIRE') {
                return !!(model.get('ACCOUNTFILTER') && (model.get('BENE_BANK_ID') || model.get('BENEBANKIDENTRYMETHOD') === 'FREEFORM'));
            }
            if (typecode === 'DRAWDOWN') {
                return !!(model.get('ACCOUNTFILTER') && model.get('BENE_BANK_ID'));
            }
            if (typecode === 'MULTIBK') {
                return !!(model.get('ACCOUNTFILTER') && model.get('BENE_BANK_ID') && model.get('CREDIT_CURRENCY'));
            }
            if (util.contains(['FEDTAX', 'DRAFT'], typecode)) {
                return !!model.get('ACCOUNTFILTER');
            }
        }
        return true;
    },

    invalidDateToggle($input, isInvalid, isMetaDriven = true) {
        const $container = $input.closest(isMetaDriven ? '.ui-widget' : '.form-group');
        const $helpBlock = $container.find('.help-block');
        const $widgetMessage = $container.find('.invalidDateUpdatedWarning');

        // The value-date widget already has logic for this. Do not duplicate the message.
        if ($widgetMessage.length) return;

        if (isInvalid) {
            $helpBlock.html(`<span class="icon-exclamation-solid"></span> ${locale.get('selected.date.not.valid')}`);
        } else {
            $helpBlock.empty();
        }
        $container.toggleClass('has-warning', isInvalid);
    },

    /**
     * @method invalidDateUpdatedWarning
     * @param {...*} options
     * @param {jQuery} options.$input - The jQuery ui element for the value date field
     * @param {boolean} options.isMetaDriven - Determines containing object for the date field
     * @param {object} options.result - Response from date/list endpoint.
     * @param {Backbone.View} options.view - The view object
     *
     * This method will clear or display help text for the indicated date field when that date is
     * invalid and has been reset to default. This method does not determine validity nor does it
     * reset the date value. This just hides or displays an inline warning message.
     */
    invalidDateUpdatedWarning(options) {
        const {
            $input,
            result: { userSetDateInvalid: isInvalid, defaultDay },
            view,
            isMetaDriven = true,
        } = options;

        if (view && defaultDay && isInvalid) {
            view.dateAfterInvalid = dateUtil.dateToUser(dateUtil.dateFromServer(defaultDay));
        }
        // Guard against duplicate calls that set the same date
        if (isInvalid || $input.val() !== view.dateAfterInvalid) {
            PaymentUtil.invalidDateToggle($input, isInvalid, isMetaDriven);
        }
    },

    /**
     * Support listening to VALUE_DATE changes to clear the invalid date warning message
     * Used in initialState of policy files
     * @param view
     * @param fieldName
     */
    invalidDateUpdateWarningReset(view, fieldName = 'VALUE_DATE') {
        const { model } = view;
        model.on(`change:${fieldName}`, (_, value) => {
            if (view.dateAfterInvalid && view.dateAfterInvalid !== value) {
                PaymentUtil.invalidDateToggle(view.$(`#${fieldName}`), false, true);
                // eslint-disable-next-line no-param-reassign
                view.dateAfterInvalid = undefined;
            }
        });
    },

    /**
     * Determine if the fieldType is of the amount type
     * @param {string} fieldType
     * @returns {boolean}
     */
    isAmountFieldType(fieldType) {
        return ['AMOUNT', 'AMOUNTSUPPRESSBLANK', 'PAMOUNT'].includes(fieldType);
    },
});

export default PaymentUtil;
