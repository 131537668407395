export default {
    // Blues
    'curiousBlue':                                  '#e8f3ff',
    'hawkesBlue':                                   '#c8dffc',
    'cobalt':                                       '#0050b5',
    'resolutionBlue':                               '#011c72',
    'matisse':                                      '#214d98',
    'dullLavender':                                 '#9eaee5',
    'stratos':                                      '#011049',
    'blizzardBlue':                                 '#B1E4F3',
    'hummingbird':                                  '#D1EEF8',

    // Grays
    'alto':                                         '#ddd',
    'alabaster':                                    '#f7f7f7',
    'wildSand':                                     '#f5f5f5',
    'mineShaft':                                    '#333',
    'silver':                                       '#ccc',
    'gallery':                                      '#ededed',
    'white':                                        '#fff',

    // Yellow
    'primrose':                                     '#e3eba2',
};