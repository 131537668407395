import palette from '../palette';

export default {
    label: {
        color: palette.mineShaft
    },

    checkboxLabel: { 
        color: palette.mineShaft 
    }
};