/*
 * TableLayout.js Override
 * Created by Chris Ward / Oct 10, 2017 *
 * This is to allow original column auto-resize functionality from Glu 1.7
 *
 * Amended by Danny Nguyen / Oct 19, 2017 (with special guest Samuel Rouse)
 * - Merged tableLayout.js (#1) - appEvents, triggerSetWidth, Footer Resize
 * (NH-42259)
 * -
 */

import TableLayout from 'no-override!@glu/grid/src/tableLayout';
import $ from 'jquery';
import util from 'underscore';
import serverConfigParams from 'system/webseries/models/configurationParameters';

const MANAGE_COLUMN_TRIGGER_WIDTH = 38;

const MIN_COLUMN_WIDTH = 65;

export default TableLayout.extend({
    appEvents: {
        resize: 'debouncedResize',
    },

    initialize(...args) {
        TableLayout.prototype.initialize.apply(this, args);

        this.grid = this.options.grid;
        this.masterCollection = this.options.masterCollection;
        this.loading = false;

        /*
         * Determine whether to load with an immediate loading state based on
         * collection's `requestingAttributes` flag
         */
        if (this.options.serverSidePagination) {
            this.loading = this.masterCollection.requestingAttributes;
        }

        /*
         * TODO `desync` event is deprecated, all logic
         *  now hangs off of the Backbone event `request`
         */
        this.listenTo(
            this.masterCollection,
            {
                'desync request': this.showLoadingView,
                'sync remove': this.hideInfoView,
            },
        );

        this.listenTo(
            this.grid,
            {
                'grid:contentRendered': this.setWidth,
            },
        );

        // New Listener for all browsers
        this.listenTo(this.grid.columns, 'change:condition add remove', this.setWidth);

        // set debounced resize method to be used as resize handler
        this.debouncedResize = util.debounce(this.triggerSetWidth.bind(this), 100);
    },

    onClose() {
        $(window).off(`resize.grid.${this.options.grid.cid}`);
    },

    onRender() {
        TableLayout.prototype.onRender.apply(this);

        if (!this.options.disableDynamicColumnWidths) {
            this.setWidth();
        }
    },

    triggerSetWidth() {
        /**
         *
         * Only fire table's width resize handler if the table view is visible
         *
         * In cases where a StackView is implemented and table view is not on top
         * of stack, it
         * will be hidden
         * no need for change if view is not visible
         *
         * "setWidth()" is the innate method to resize table in glu component
         *
         *
         */
        if (this.$el.is(':visible')) {
            this.setWidth();
        }
    },

    fitColumnToContent($th, columnIndex, $rows) {
        const columnModel = this.options.grid.columns.at(columnIndex);
        const $cell = $rows.find(`th:eq("${columnIndex}")`);
        const cellPadding = $cell.outerWidth() - $cell.width();
        let width = 0;

        // If column has initial width do not change it during sorting/filtering actions
        if (columnModel && columnModel.get('width') !== 'auto') {
            width = columnModel.get('width');
        } else {
            $rows.each(function () {
                const contentWidth = $(this).find(`th:eq("${columnIndex}") > *`).outerWidth();
                if (contentWidth > width) {
                    width = contentWidth;
                }
            });
            width += cellPadding;
        }

        $th.css({
            width: width || $th.width(),
            minWidth: width || $th.css('min-width'),
        });
    },

    setWidth(model, resizeWidth) {
        /*
         * Grids with disableDynamicColumnWidths enabled do not use this function for
         * table column widths, they use CSS only to manage widths
         */
        if (this.options.disableDynamicColumnWidths) {
            return;
        }

        if (this.isClosed) {
            return;
        }

        const getHeaderModel = cid => this.header.currentView.collection.get(cid);
        const getHeaderFallback = (el) => {
            window.console.log('Missing model for column', el.innerText);
            return {
                get: () => undefined,
            };
        };

        const $table = this.$('table');
        const $canvas = this.$('.js-canvas');
        const $footer = this.grid.$('.footer');
        let viewportWidth = this.$el.width();
        let tableWidth = $table.width();
        let newSetWidth = (viewportWidth > tableWidth) ? viewportWidth : tableWidth;

        $table.css({
            'table-layout': 'auto',
            width: 'auto',
        });

        /*
         * FROM CNB (related to IDT-38508)
         * If we are on a grid that is indicated through a config param to be statically
         * sized based on the column metadata, we want to limit the table width to the
         * total column width when that value is less than the natural width of the table.
         */
        let totalColumnWidth = 0;
        const staticGridList = serverConfigParams.get('staticGridList');
        const actionContext = this.options.masterCollection.context?.actionContext;
        const key = actionContext ? `${actionContext.typeCode}~${actionContext.productCode}~${
            actionContext.functionCode}` : null;
        const isStaticGrid = staticGridList && staticGridList.includes(key);
        if (isStaticGrid) {
            this.options.grid.columns.each((columnModel) => {
                if (!columnModel.get('hidden')) {
                    totalColumnWidth += columnModel.get('width') || 0;
                }
            });
            if (totalColumnWidth > viewportWidth) {
                if (totalColumnWidth < tableWidth) {
                    newSetWidth = totalColumnWidth;
                } else {
                    newSetWidth = tableWidth;
                }
            } else {
                newSetWidth = viewportWidth;
            }
        } else {
            newSetWidth = '';
        }

        // Adjust width including visible scrollbar
        if (this.$el[0].scrollHeight > this.$el.height()) {
            viewportWidth = this.$(this.info.el).width();
        }

        // Reset sizing containers to parent container
        tableWidth = newSetWidth;
        $table.width(newSetWidth);
        $canvas.width(newSetWidth);
        if (viewportWidth > 0) {
            $footer.width(viewportWidth);
        }

        const $tableHeaders = $table.find('thead th');

        $tableHeaders.each(function (idx, el) {
            const $th = $(el);
            const headerModel = getHeaderModel($th.data('cid'))
                ?? getHeaderFallback(el);
            const headerModelWidth = headerModel.get('width') ?? (headerModel.get('fieldName') === 'PAYMENT_DETAIL' && headerModel.get('fieldSize'));
            const { width } = window.getComputedStyle(this);
            const isGridActionCell = $th.hasClass('grid-action');
            const isManageColumnTextOverlap = idx === $tableHeaders.length - 1 &&
            $th.width() < MIN_COLUMN_WIDTH;
            const usableWidth = (headerModelWidth) || ((isGridActionCell) ? '80'
                : Math.ceil(parseInt(width, 10)) + 1);

            /*
             * If we have a model/resizeWidth arguments, this is coming from a user
             * resize of a column. If we have no model, this is just a normal render.
             * Only set a size if this is a normal render or the resized column
             * matches the cid of the headerModel for this column.
             * The used width will default to resizeWidth since if it is around we
             * should use that from the user resize. In the event it's not around
             * it's because it's a normal render so it'll fall into the usableWidth value.
             *
             * If no model exists, do a normal render
             * If model exists but not resizeWidth, columns are changing, do a normal render
             * If model and resizeWidth exists, user event only set width on column user is changing
             *
             */
            const isNormalRender = !model || (model && !resizeWidth);
            const isUserUpdate = model && resizeWidth && model.cid === headerModel.cid;

            const constrainedWidth = (headerModel.get('fieldName') === 'PAYMENT_DETAIL' &&
                parseInt(serverConfigParams.get('TransactionDetailMaxColumnWidth'), 10));
            const adjustedConstrainedWidth = constrainedWidth && `${constrainedWidth}ch`;
            const usableConstrainedWidth = (isStaticGrid && headerModelWidth) ? headerModelWidth
                : adjustedConstrainedWidth;

            const minimumColumnWidth = parseInt(serverConfigParams.get('minimumGridColumnWidth'), 10) || MIN_COLUMN_WIDTH;
            const newColumnWidth = resizeWidth || usableConstrainedWidth || usableWidth;
            const actualSetWidth = parseInt(newColumnWidth, 10) < minimumColumnWidth ?
                minimumColumnWidth : newColumnWidth;

            if (isNormalRender || isUserUpdate) {
                const headerCellWidth = isManageColumnTextOverlap ?
                    (actualSetWidth + MANAGE_COLUMN_TRIGGER_WIDTH) : actualSetWidth;
                $th.css({
                    width: usableConstrainedWidth ? `min(${usableConstrainedWidth}, ${usableWidth}px)` : headerCellWidth,
                    // allow cells to be resized down to a narrow column size
                    minWidth: isGridActionCell ? usableWidth : `${MIN_COLUMN_WIDTH}px`,
                    maxWidth: headerCellWidth,
                });
            }
        });
        $table.css({
            'table-layout': 'fixed',
        });

        // make sure column toggle height is calculated correctly
        this.grid.trigger('grid:columnResizeComplete', { styleOnly: true });

        // adjust footer width to match the current grid
        if (this.$el.closest('.dashboard-region').length) {
            this.grid.footer.$el.css('width', window.getComputedStyle(this.el).width);
        }
    },
});
