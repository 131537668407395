import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ThemeProvider, legacyTheme, ashTheme } from '@glu/theming';
// import available client themes here
import {
    // ashTheme as corpAshTheme,
    legacyTheme as corpLegacyTheme
} from './themes/corp';

// Temporary fix until we have nested theme provider solution for branding
const { palette: { colors: ashColorNames } } = ashTheme();

const Branding = ({ children, baseTheme: baseThemeProp }) => {
    const userTheme = useSelector(state => state.userInfo.theme || 'client');

    const baseTheme = useMemo(() => {
        switch (baseThemeProp) {
            case 'ash':
                return ashTheme;
            case 'legacy':
                return legacyTheme;
            default:
                return legacyTheme;
        }
    }, [baseThemeProp]);

    const theme = useMemo(() => {
        switch(userTheme) {
            case 'client':
                return {
                    ...corpLegacyTheme,
                    palette: {
                        ...(corpLegacyTheme.palette || {}),
                        colors: {
                            ...ashColorNames,
                            ...(corpLegacyTheme?.palette?.colors || {}),
                        }
                    }
                };
            default:
                return {
                    ...corpLegacyTheme,
                    palette: {
                        ...(corpLegacyTheme.palette || {}),
                        colors: {
                            ...ashColorNames,
                            ...(corpLegacyTheme?.palette?.colors || {}),
                        }
                    }
                };
        };
    }, [userTheme]);

    return (
        <ThemeProvider theme={theme} baseTheme={baseTheme}>
            {children}
        </ThemeProvider>
    );
};

Branding.propTypes = {
    children: PropTypes.node.isRequired,
    baseTheme: PropTypes.oneOf('ash', 'legacy')
};

Branding.defaultProps = {
    baseTheme: 'legacy'
};

export default Branding;
