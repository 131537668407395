import appConfigParams from 'system/webseries/models/applicationConfiguration';

const SCREEN_SIZE = {
    TABLET: 768,
    MOBILE_LARGE: 430,
};

/**
 * Return a function that will check if the screen size
 * is less than or equal to the window innerWidth
 * @param {number} size - window screen size
 * @returns {function}
 */
const isScreenSizeOrLess = size => () => window.matchMedia(`(max-width: ${size}px)`).matches;

/**
 * Return a function that will check if the screen size
 * is grater than or equal to the window innerWidth
 * @param {number} size - window screen size
 * @returns {function}
 */
const isScreenSizeOrGreater = size => () => window.matchMedia(`(min-width: ${size}px)`).matches;

/**
 * Native application sets the mobileInfo object, so when it is set,
 * we know this is the native application
 * @returns {boolean}
 */
export const isNativeApp = () => !!window.mobileInfo;

export const isMobileScreen = () => {
    // Support the more general check if the screen returns zero width;
    const width = window.screen.width || window.innerWidth;
    return width <= SCREEN_SIZE.MOBILE_LARGE;
};

export default {
    /**
     * Native applicaiton sets the mobileInfo object, so when it is set,
     * we know this is the native application
     * @returns {boolean}
     */
    isNativeApp,
    isMobileScreen,

    /**
     * Less than or equal to MOBILE_LARGE
     */
    isMobileLarge: isScreenSizeOrLess(SCREEN_SIZE.MOBILE_LARGE),

    /**
     * Less than or equal to TABLET
     */
    isTablet: isScreenSizeOrLess(SCREEN_SIZE.TABLET),

    /**
     * Not Greater than or equal to TABLET
     */
    isHamburgerMenu: () => !isScreenSizeOrGreater(SCREEN_SIZE.TABLET)(),

    /**
     * Is mobile screen and has mobile grid app config enabled
     */
    isMobileGridEnabled() {
        const mobileGridEnabled = appConfigParams.get('MOBILE_MobileShowGrid') === '1';
        return this.isMobileScreen() && mobileGridEnabled;
    },

    /**
     * Provide base function for checking if screen size matches
     */
    isScreenSizeOrLess,
};
