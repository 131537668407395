import palette from '../palette';

export default {
  summaryFooter: {
    backgroundColor: palette.cobalt,
    color: palette.white
  },
 
  footerButton: { 
    borderColor: palette.resolutionBlue,

    '&:hover': {
      borderColor: palette.resolutionBlue,
    }
  },

  footerButtonPrimary: {
    background: palette.resolutionBlue,
    borderColor: palette.resolutionBlue,
    color: palette.white,

    '&:hover, &:focus': {
      background: palette.stratos,
      borderColor: palette.stratos,
      color: palette.white
    },
    '&.disabled': {
      background: palette.dullLavender,
      color: palette.white,
    }
  },
  footerButtonSecondary: {
    background: palette.white,
    borderColor: palette.white,
    color: palette.cobalt,

    '&:hover, &:focus': {
      background: palette.silver,
      borderColor: palette.white,
      color: palette.mineShaft
    },
    '&.disabled': {
      background: palette.gallery,
      color: palette.dullLavender
    }
  }
};